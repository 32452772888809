import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  TemplateRef,
  input,
  output,
} from '@angular/core';
import { NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { DateProxyPipe } from '@desquare/components/common/src/pipe/pipe/date-proxy.pipe';
import { MomentModule } from 'ngx-moment';
import { TranslateModule } from '@ngx-translate/core';
import { IDesignageDataTableColumns } from '@desquare/interfaces';
import { getHierarchicalFieldValue } from '../hierarchicalFields.util';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { FormsModule, NgModel } from '@angular/forms';
import { TableDeviceArrayComponent } from '../table-device-array/table-device-array.component';
import { TableGenericArrayComponent } from '../table-generic-array/table-generic-array.component';
import { TableStatusIndicatorComponent } from '../table-status-indicator/table-status-indicator.component';
import { TableOsTypePipe } from '../../pipe/table-os-type/table-os-type.pipe';
import { FileSizePipe } from '../../pipe/file-size/file-size.pipe';

@Component({
  standalone: true,
  imports: [
    NgTemplateOutlet,
    FormsModule,
    NgbPopoverModule,
    NgbTooltipModule,
    FileSizePipe,
    MomentModule,
    TranslateModule,
    MatCheckbox,
    TableStatusIndicatorComponent,
    TableDeviceArrayComponent,
    TableGenericArrayComponent,
    TableOsTypePipe,
  ],
  selector: 'table-child-cell',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    @defer (on viewport) {
      @if (rowElement() && column()) {
        <!-- Check if data is boolean. If Boolean create checkboxes. -->
        @if (column().type === 'boolean') {
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="emitCheckboxChange(rowElement(), $event)"
            [ngModel]="rowElement()[column().fieldName]"
            [disabled]="readOnly()"
            color="secondary"
          >
            <!-- (ngModelChange)="emitCheckboxChange(rowElement(), $event)" -->
          </mat-checkbox>
        }

        <!-- device status indicator (get status from service, field contains deviceId) -->
        @if (
          column().type === 'device-status-indicator' ||
          column().type === 'status-indicator'
        ) {
          <table-status-indicator
            [status]="rowElement().status"
          ></table-status-indicator>
        }

        <!-- Check if data is string -->
        @if (column().type === 'string') {
          @switch (column().decorator) {
            <!-- OS Icon string -->
            @case ('osType') {
              <span
                class="d-flex align-items-center text-truncate"
                [innerHTML]="
                  getFieldValue(rowElement(), column().fieldName) | tableOsType
                "
              ></span>
            }
            @case ('fileSize') {
              <!-- pipe fileSize -->
              <span
                class="text-truncate"
                [ngbTooltip]="getFieldValue(rowElement(), column().fieldName)"
              >
                {{ getFieldValue(rowElement(), column().fieldName) | fileSize }}
              </span>
            }
            @default {
              <!-- Generic string -->
              <span
                class="text-truncate"
                [ngbTooltip]="getFieldValue(rowElement(), column().fieldName)"
              >
                {{
                  getFieldValue(rowElement(), column().fieldName) | translate
                }}
              </span>
            }
          }
        }

        <!-- Component -->
        @if (column().type === 'template') {
          <ng-container
            [ngTemplateOutlet]="getCustomTemplate(column().templateRef)"
            [ngTemplateOutletContext]="{ $implicit: rowElement() }"
          />
        }

        <!-- Check if data is object -->
        @if (column().type === 'object') {
          {{ getFieldValue(rowElement(), column().fieldName) }}
        }

        <!-- Check if data is Device Array -->
        @if (column().type === 'device-array') {
          <table-device-array
            [devicesArray]="getFieldValue(rowElement(), column().fieldName)"
          />
        }
        @if (column().type === 'generic-array') {
          @if (rowElement()[column().fieldName].length > 0) {
            <table-generic-array
              [inputArray]="getFieldValue(rowElement(), column().fieldName)"
              [params]="column()"
            ></table-generic-array>
          } @else {
            <div class="badge bg-secondary">
              {{ column().noDataMessage ?? 'NO_DATA' | translate }}
            </div>
          }
        }
      }
    } @placeholder {
      <div
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      >
        <span class="text-secondary visually-hidden">
          {{ 'LOADING' | translate }}...
        </span>
      </div>
    }
  `,
  styles: [
    `
      :host {
        display: flex;
        justify-content: start;
        align-items: center;
        width: 100%;
      }
    `,
  ],
})
export class TableChildCellComponent {
  rowElement = input.required<any>();
  column = input.required<IDesignageDataTableColumns>();
  readOnly = input<boolean>(false);
  customComponent = input<TemplateRef<any>[]>();
  rowCheckboxChange = output<{ row: unknown; event: MatCheckboxChange }>();

  getFieldValue(row: any, fieldName: string) {
    if (!fieldName.includes('.')) return row[fieldName];
    const value = getHierarchicalFieldValue(row, fieldName);
    if (value === null) {
      return '-';
    }
    return value;
  }

  getCustomTemplate(template: string | undefined): TemplateRef<any> | null {
    if (!template) return null;
    return (
      this.customComponent()?.find((t) =>
        (<any>t)._declarationTContainer?.localNames?.includes(template),
      ) || null
    );
  }

  //TODO: implement with column click to support multiple checkbox fields
  emitCheckboxChange(row: unknown, event: MatCheckboxChange) {
    this.rowCheckboxChange.emit({ row, event });
  }
}
